<template>
  <Header
    ref="headerRef"
    type="INCOME_STATEMENT"
    :title="t('menus.Reports')"
    :show-regenerate-button="false"
    :updated-on="updatedTime ?? ''"
  />
  <ReportsMenus
    ref="ReportsMenusRef"
    type="INCOME_STATEMENT"
    :params="params"
    :title="t('project.nav.incomeStatement')"
    @onChangDateTime="onChangDateTime"
  />

  <div v-loading="loading" class="elv-reports-income-statement-content">
    <FinancialStatement
      financial-type="income-statement"
      :params="params"
      :is-show-regenerated-info="ReportsMenusRef?.isShowRegeneratedInfo"
    />
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import Header from '../components/Header.vue'
import ReportsMenus from '../../components/ReportsMenus.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import FinancialStatement from '../components/FinancialStatement.vue'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const { loading } = storeToRefs(reportStore)
const headerRef = ref()
const ReportsMenusRef = ref()

const updatedTime = computed(() => {
  return reportStore.financialData.updatedOn
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const params = computed(() => {
  return {
    period:
      ReportsMenusRef.value?.periodValue === 'date' ? 'DAY' : ReportsMenusRef.value?.periodValue.toLocaleUpperCase(),
    dateRange: ReportsMenusRef.value?.dateValue
  }
})

const onChangDateTime = async (data: object) => {
  await reportStore.financialsDataInit(entityId.value, 'income-statement', data)
}
</script>
<style lang="scss" scoped>
.elv-reports-income-statement-content {
  min-height: 65vh;
  height: calc(100% - 163px);
}
</style>
